import { Button } from '../button';

// ----------------------------------------------------------------------

type Props = {
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
};

export default function LayoutOptions({ options, value, onChange }: Props) {
  const renderNav = (option: string, selected: boolean) => {
    const baseStyles = 'flex-shrink-0 rounded-lg bg-muted/50';

    const circle = (
      <div
        className={`${baseStyles} w-2 h-2 ${
          selected ? 'bg-gradient-to-tr from-primary/50 to-primary' : ''
        }`}
      />
    );

    const primaryItem = (
      <div
        className={`
          ${baseStyles} h-[3px] opacity-50
          ${option === 'horizontal' ? 'w-3' : 'w-full'}
          ${selected ? 'bg-gradient-to-tr from-primary/50 to-primary' : ''}
        `}
      />
    );

    const secondaryItem = (
      <div
        className={`
          ${baseStyles} h-[3px] max-w-3 opacity-25
          ${option === 'horizontal' ? 'w-2' : 'w-full'}
          ${selected ? 'bg-gradient-to-tr from-primary/50 to-primary' : ''}
        `}
      />
    );

    return (
      <div
        className={`
          flex-shrink-0 p-0.5 w-28 h-full
          border-muted/20
          ${
            option === 'horizontal'
              ? 'flex flex-row border-r'
              : 'flex flex-col h-[16px] items-center border-b border-r-0'
          }
        `}
      >
        {circle}
        {primaryItem}
        {secondaryItem}
      </div>
    );
  };

  const renderContent = (selected: boolean) => (
    <div className='p-0.5 flex-grow h-full w-full'>
      <div
        className={`
          w-full h-full rounded-lg
          ${
            selected
              ? 'opacity-25 bg-gradient-to-tr from-primary/50 to-primary'
              : 'opacity-10 bg-muted/50'
          }
        `}
      />
    </div>
  );

  return (
    <div className='flex flex-row gap-2'>
      {options.map(option => {
        const selected = value === option;

        return (
          <Button
            key={option}
            variant='outline'
            onClick={() => onChange(option)}
            className={`
              p-0 w-full h-14 
              border border-muted/10
              ${selected ? 'bg-background shadow-lg' : ''}
              ${option === 'horizontal' ? 'flex-col' : ''}
            `}
          >
            {renderNav(option, selected)}
            {renderContent(selected)}
          </Button>
        );
      })}
    </div>
  );
}
