import { ProductFruits } from 'react-product-fruits';
// utils
import { jwtDecode } from '@utils/jwt';
import { getSessionToken } from '@utils/session';

const productFruitsOnboarding = () => {
  const session = getSessionToken();
  const user = jwtDecode(session);

  const userInfo = {
    username: user?.username || '',
    email: user?.username || ''
  };

  const PF_KEY: string =
    import.meta.env.VITE_REACT_APP_PRODUCT_FRUITS_KEY?.toString() || '';
  if (!session) return <></>;

  return <ProductFruits workspaceCode={PF_KEY} language='en' user={userInfo} />;
};

export default productFruitsOnboarding;
