import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export function jwtDecode(token?: string) {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    enqueueSnackbar('Invalid token', { variant: 'error' });
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isTokenExpired = (accessToken?: string) => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return currentTime > decoded.exp;
};
