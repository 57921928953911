import { UserInfoDto } from '@growdash/identity-client/generated';
import { authorizeIdentityService } from '@lib/identity';
import axios from '@utils/axios';
import { isTokenExpired } from './jwt';

// Constants
export const SESSION_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
// Helpers
// ----------------------------------------------------------------------
export const setSessionObject = ({
  key,
  value
}: {
  key: string;
  value: any;
}) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionObject = ({ key }: { key: string }) => {
  return JSON.parse(sessionStorage.getItem(key) || '{}');
};

// Clear cookies
export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const [name] = cookie.split('=');
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

// Clear cache
export const clearCache = () => {
  caches
    .keys()
    .then(names => Promise.all(names.map(name => caches.delete(name))));
};

// ----------------------------------------------------------------------

export const getSessionToken: () => string | undefined = () => {
  return getCookie(SESSION_KEY) ?? undefined;
};

export const getSessionRefreshToken: () => string | undefined = () => {
  return getCookie(REFRESH_TOKEN_KEY) ?? undefined;
};

export const getSessionUser: () => UserInfoDto = () => {
  return getSessionObject({ key: 'user' });
};

export const setSession = async (accessToken: string | null) => {
  if (accessToken && !isTokenExpired(accessToken)) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    document.cookie = `${SESSION_KEY}=${accessToken}; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/`;
    authorizeIdentityService(accessToken);
  } else {
    clearSession();
  }
};

export const setSessionRefreshToken = async (refreshToken: string | null) => {
  if (refreshToken) {
    document.cookie = `${REFRESH_TOKEN_KEY}=${refreshToken};`;
  }
};

export const getCookie = (name: string): string | null => {
  const cookieString = document.cookie;
  const cookiePairs = cookieString
    .split(';')
    .map(pair => pair.trim().split('='));

  const cookie = cookiePairs.find(([cookieName]) => cookieName === name);

  return cookie ? cookie[1] : null;
};

// Clear both session, cookies and cache
export const clearSession = () => {
  clearCookies();
  clearCache();
  sessionStorage.clear();
  axios.defaults.headers.common.Authorization = '';
};
