import { forwardRef } from 'react';

// ----------------------------------------------------------------------

export interface LogoProps {
  disabledLink?: boolean;
  className?: string;
}

// TODO: fix this
const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, className }) => {
    const logo = (
      <img
        alt='logo'
        src='/logo/2.png'
        className={`w-72 h-18 cursor-pointer ${className}`}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <a href="/" style={{ display: 'contents' }}>
        {logo}
      </a>
    );
  }
);

export default Logo;
