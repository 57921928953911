import { Info, RefreshCcw, X } from 'lucide-react';
import { Badge } from '../badge';
import { Button } from '../button';
import { Drawer, DrawerClose, DrawerContent } from '../drawer';

import Scrollbar from '../scrollbar';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip';
import { Typography } from '../typography';
import BaseOptions from './base-option';
import FullScreenOption from './fullscreen-option';
import LayoutOptions from './layout-options';
import PresetsOptions from './presets-options';
import StretchOptions from './stretch-options';

type SettingsProps = {
  themeStretch: boolean;
  themeMode: 'light' | 'dark';
  themeDirection: 'rtl' | 'ltr';
  themeContrast: 'default' | 'bold';
  themeLayout: 'mini' | 'vertical' | 'horizontal';
  themeColorPresets: 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red';
  onUpdate: (name: string, value: string | boolean) => void;
  onChangeDirectionByLang: (lang: string) => void;
  canReset: boolean;
  onReset: VoidFunction;
  open: boolean;
  onToggle: VoidFunction;
  onClose: VoidFunction;
};

export default function SettingsDrawer({
  settings
}: {
  settings: SettingsProps;
}) {
  const renderHead = (
    <div className='flex items-center justify-between py-2 px-4 border-b'>
      <Typography variant='h6'>Settings</Typography>

      <div className='flex items-center gap-2'>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant='ghost'
              size='icon'
              onClick={settings.onReset}
              className='relative'
            >
              <Badge
                variant='destructive'
                className={`absolute -top-1 -right-1 h-3 w-3 p-0 ${
                  !settings.canReset ? 'hidden' : ''
                }`}
              />
              <RefreshCcw className='h-5 w-5' />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Reset</TooltipContent>
        </Tooltip>

        <DrawerClose asChild>
          <Button variant='ghost' size='icon' onClick={settings.onClose}>
            <X className='h-5 w-5' />
          </Button>
        </DrawerClose>
      </div>
    </div>
  );

  const renderMode = (
    <div className='space-y-1.5'>
      <Typography
        variant='caption'
        className='text-muted-foreground font-semibold'
      >
        Mode
      </Typography>

      <BaseOptions
        value={settings.themeMode}
        onChange={(newValue: string) =>
          settings.onUpdate('themeMode', newValue)
        }
        options={['light', 'dark']}
        icons={['sun', 'moon']}
      />
    </div>
  );

  const renderContrast = (
    <div className='space-y-1.5'>
      <Typography
        variant='caption'
        className='text-muted-foreground font-semibold'
      >
        Contrast
      </Typography>

      <BaseOptions
        value={settings.themeContrast}
        onChange={(newValue: string) =>
          settings.onUpdate('themeContrast', newValue)
        }
        options={['default', 'bold']}
        icons={['contrast', 'contrast_bold']}
      />
    </div>
  );

  const renderDirection = (
    <div className='space-y-1.5'>
      <Typography
        variant='caption'
        className='text-muted-foreground font-semibold'
      >
        Direction
      </Typography>

      <BaseOptions
        value={settings.themeDirection}
        onChange={(newValue: string) =>
          settings.onUpdate('themeDirection', newValue)
        }
        options={['ltr', 'rtl']}
        icons={['align_left', 'align_right']}
      />
    </div>
  );

  const renderLayout = (
    <div className='space-y-1.5'>
      <Typography
        variant='caption'
        className='text-muted-foreground font-semibold'
      >
        Layout
      </Typography>

      <LayoutOptions
        value={settings.themeLayout}
        onChange={(newValue: string) =>
          settings.onUpdate('themeLayout', newValue)
        }
        options={['vertical', 'horizontal', 'mini']}
      />
    </div>
  );

  const renderStretch = (
    <div className='space-y-1.5'>
      <div className='flex items-center gap-1'>
        <Typography
          variant='caption'
          className='text-muted-foreground font-semibold'
        >
          Stretch
        </Typography>
        <Tooltip>
          <TooltipTrigger asChild>
            <Info className='h-4 w-4 text-muted-foreground' />
          </TooltipTrigger>
          <TooltipContent>
            Only available at large resolutions {'>'}1600px (xl)
          </TooltipContent>
        </Tooltip>
      </div>

      <StretchOptions
        value={settings.themeStretch}
        onChange={() =>
          settings.onUpdate('themeStretch', !settings.themeStretch)
        }
      />
    </div>
  );

  const renderPresets = (
    <div className='space-y-1.5'>
      <Typography
        variant='caption'
        className='text-muted-foreground font-semibold'
      >
        Presets
      </Typography>

      <PresetsOptions
        value={settings.themeColorPresets}
        onChange={(newValue: string) =>
          settings.onUpdate('themeColorPresets', newValue)
        }
      />
    </div>
  );

  return (
    <Drawer open={settings.open} onOpenChange={settings.onClose}>
      <DrawerContent side='right' className='w-[280px]'>
        {renderHead}

        <Scrollbar className='flex-1 px-4'>
          <div className='flex flex-col gap-4 py-4'>
            {renderMode}
            {renderContrast}
            {renderDirection}
            {renderLayout}
            {renderStretch}
            {renderPresets}
          </div>
        </Scrollbar>

        <FullScreenOption />
      </DrawerContent>
    </Drawer>
  );
}
