import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { cn } from '../../../lib/utils';

const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
Drawer.displayName = 'Drawer';

const DrawerTrigger: typeof DrawerPrimitive.Trigger = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>
>(({ ...props }, ref) => <DrawerPrimitive.Trigger ref={ref} {...props} />);
DrawerTrigger.displayName = 'DrawerTrigger';

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose: typeof DrawerPrimitive.Close = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Close>
>(({ ...props }, ref) => <DrawerPrimitive.Close ref={ref} {...props} />);
DrawerClose.displayName = 'DrawerClose';

const DrawerOverlay: React.FC<
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
> = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-black/80', className)}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

type DrawerContentProps = React.ComponentPropsWithoutRef<
  typeof DrawerPrimitive.Content
> & {
  width?: number;
  elevation?: number;
  side?: 'left' | 'right' | 'top' | 'bottom';
};

const DrawerContent: React.FC<DrawerContentProps> = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  DrawerContentProps
>(
  (
    {
      className,
      children,
      width = 280,
      elevation = 8,
      side = 'right',
      ...props
    },
    ref
  ) => {
    // Elevation değerine göre shadow değerini belirleme
    const getShadowClass = (elevation: number) => {
      const shadows = {
        0: 'shadow-none',
        1: 'shadow-sm',
        2: 'shadow',
        4: 'shadow-md',
        8: 'shadow-lg',
        16: 'shadow-xl',
        24: 'shadow-2xl'
      };
      return shadows[elevation as keyof typeof shadows] || 'shadow-lg';
    };

    // Define position styles based on side
    const sideStyles: { [key: string]: React.CSSProperties } = {
      left: { left: 0, top: 0, bottom: 0, width: `${width}px`, height: '100%' },
      right: {
        right: 0,
        top: 0,
        bottom: 0,
        width: `${width}px`,
        height: '100%'
      },
      top: { top: 0, left: 0, right: 0, height: `${width}px`, width: '100%' },
      bottom: { bottom: 0, left: 0, right: 0, height: 'auto', width: '100%' }
    };

    // Define classes based on side
    const sideClasses = {
      left: 'rounded-r-[10px]',
      right: 'rounded-l-[10px]',
      top: 'rounded-b-[10px]',
      bottom: 'rounded-t-[10px]'
    };

    return (
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerPrimitive.Content
          ref={ref}
          style={sideStyles[side]}
          className={cn(
            'fixed z-50 flex flex-col border bg-background p-6',
            'transition-all duration-300',
            getShadowClass(elevation),
            sideClasses[side],
            'dark:shadow-[0_-40px_80px_-8px_rgba(0,0,0,0.24)]',
            className
          )}
          {...props}
        >
          {side === 'bottom' && (
            <div className='mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted' />
          )}
          {children}
        </DrawerPrimitive.Content>
      </DrawerPortal>
    );
  }
);
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('grid gap-1.5 p-4 text-center sm:text-left', className)}
    {...props}
  />
);
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('mt-auto flex flex-col gap-2 p-4', className)}
    {...props}
  />
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle: React.FC<
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
> = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold leading-none tracking-tight',
      className
    )}
    {...props}
  />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription: React.FC<
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
> = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger
};
