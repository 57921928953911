// routes
import { paths } from '@routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = import.meta.env.REACT_APP_HOST_API;
export const BASE_URL = import.meta.env.VITE_REACT_APP_BACKEND_BASE_URL;
export const AISHA_GOALS_API_URL = import.meta.env
  .VITE_REACT_APP_AISHA_GOALS_API_URL;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.overview; // as '/dashboard'
