export enum ChannelType {
  Deliveroo = 'deliveroo',
  Talabat = 'talabat',
  Careem = 'careem',
  JustEat = 'just-eat',
  Noon = 'noon',
  Hungerstation = 'hungerstation',
  Glovo = 'glovo',
  Bolt = 'bolt',
  Tazz = 'tazz',
  Cari = 'cari',
  Jahez = 'jahez',
  Toyou = 'to-you',
  Keeta = 'keeta'
}
