import { cva, type VariantProps } from 'class-variance-authority';
import React, { ElementType } from 'react';
import { cn } from '../../../lib/utils';

const typographyVariants = cva('', {
  variants: {
    variant: {
      h1: 'text-5xl font-bold',
      h2: 'text-4xl font-bold',
      h3: 'text-3xl font-semibold',
      h4: 'text-2xl font-semibold',
      h5: 'text-xl font-medium',
      h6: 'text-lg font-medium',
      subtitle1: 'text-lg font-normal',
      subtitle2: 'text-base font-medium',
      body1: 'text-base',
      body2: 'text-sm',
      button: 'text-sm font-bold uppercase tracking-wide',
      caption: 'text-xs font-light',
      overline: 'text-xs font-bold uppercase tracking-widest',
      inherit: ''
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
      justify: 'text-justify'
    }
  },
  defaultVariants: {
    variant: 'body1'
  }
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {
  component?: ElementType;
  fontWeight?: number;
}

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (
    { className, variant = 'body1', align, fontWeight, component, ...props },
    ref
  ) => {
    const Comp = component || 'p';
    return (
      <Comp
        ref={ref}
        style={{
          fontWeight
        }}
        className={cn(typographyVariants({ variant, align }), className)}
        {...props}
      />
    );
  }
);

Typography.displayName = 'Typography';

export { Typography, typographyVariants };
