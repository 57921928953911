import { cn } from '../../../../lib/utils';
import { SvgColor } from '../svg-color';

// ----------------------------------------------------------------------

type Props = {
  icons: string[];
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
};

export default function BaseOptions({
  icons,
  options,
  value,
  onChange
}: Props) {
  return (
    <div className='flex flex-row gap-2'>
      {options.map((option, index) => {
        const selected = value === option;

        return (
          <button
            key={option}
            onClick={() => onChange(option)}
            className={cn(
              'relative w-full h-20 rounded border border-gray-500/[0.08]',
              'transition-all duration-200 hover:bg-accent/50',
              'focus-visible:outline-none focus-visible:ring-2',
              'focus-visible:ring-ring focus-visible:ring-offset-2',
              selected && [
                'bg-background',
                'shadow-["-24px_8px_24px_-4px_rgba(0,0,0,0.08)]',
                'dark:shadow-["-24px_8px_24px_-4px_rgba(0,0,0,0.2)]'
              ]
            )}
          >
            <SvgColor
              src={`/assets/icons/setting/ic_${
                index === 0 ? icons[0] : icons[1]
              }.svg`}
              className={cn(
                'bg-gradient-to-br from-gray-500 to-gray-600',
                selected && 'from-primary/50 to-primary'
              )}
            />
          </button>
        );
      })}
    </div>
  );
}
