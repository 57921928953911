// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

Sentry.init({
  dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN as string,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error']
    }),
    Sentry.httpClientIntegration({
      failedRequestTargets: [
        import.meta.env.VITE_REACT_APP_BACKEND_BASE_URL as string,
        /^https:\/\/.*\.mygrowdash\.com$/
      ]
    })
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    import.meta.env.VITE_REACT_APP_BACKEND_BASE_URL as string,
    /^https:\/\/.*\.mygrowdash\.com$/,
    'localhost'
  ],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
