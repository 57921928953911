import { Command as CommandPrimitive } from 'cmdk';
import { Check, Loader2Icon } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '../../../../lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  Input,
  Popover,
  PopoverAnchor,
  PopoverContent
} from '../../atoms';
import { OptionType, RenderedOptionProps } from './types';

type AutoCompleteProps = {
  value?: any;
  onValueChange?: (value: any) => void;
  inputValue?: string;
  getOptionId: (option: any) => string | number;
  onInputChange?: (value: string) => void;
  options: OptionType[];
  isLoading?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  noOptionsText?: string;
  renderOption?: (option: any, props?: RenderedOptionProps) => JSX.Element;
  renderInput?: (params: any) => JSX.Element;
  className?: string;
  inputClassName?: string;
  extractValue?: (item: OptionType) => any;
  extractLabel?: (item: OptionType) => string;
  freeText?: boolean;
  getOptionDisabled?: (option: OptionType) => boolean;
  disabled?: boolean;
};

export function AutoComplete({
  value,
  onValueChange,
  inputValue,
  onInputChange,
  getOptionId,
  options,
  isLoading,
  emptyMessage = 'No options.',
  placeholder = 'Search...',
  renderOption,
  renderInput,
  className = '',
  inputClassName = '',
  extractValue = (option: OptionType) => option,
  extractLabel = (option: OptionType) => {
    if (typeof option === 'string') return option;
    return option?.label ?? '';
  },
  freeText = false,
  getOptionDisabled = () => false,
  disabled = false
}: AutoCompleteProps) {
  const [open, setOpen] = useState(false);
  const [internalInputValue, setInternalInputValue] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(
    undefined
  );

  const reset = () => {
    if (onValueChange) onValueChange(null);
    if (onInputChange) onInputChange('');
    setInternalInputValue('');
  };

  const initialRender = useRef(true);

  useEffect(() => {
    if (value) {
      const selectedOption = Object.values(identifiedOptions).find(
        option => JSON.stringify(option.value) === JSON.stringify(value)
      );
      if (selectedOption) {
        setSelectedOptionId(selectedOption.id);
      }
    } else {
      setSelectedOptionId(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (!selectedOptionId) {
      if (!initialRender.current) reset();
    } else {
      const selectedItem = identifiedOptions[selectedOptionId];
      if (onValueChange) onValueChange(selectedItem?.value);
      if (onInputChange) onInputChange(selectedItem.label ?? '');
      setInternalInputValue(selectedItem.label ?? '');
      setOpen(false);
    }
    initialRender.current = false;
  }, [selectedOptionId]);

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (freeText) return;
    if (!e.relatedTarget?.hasAttribute('cmdk-list')) {
      // Bring input value back to the selected value if user clicks outside
      const currentOptionLabel = selectedOptionId
        ? identifiedOptions?.[selectedOptionId]?.label
        : '';
      setInternalInputValue(currentOptionLabel);
    }
  };

  type IdentifiedOption = {
    id: string;
    label: string;
    value: any;
    option?: any;
  };

  const identifiedOptions: Record<string, IdentifiedOption> = useMemo<
    Record<string, IdentifiedOption>
  >(() => {
    return options.reduce((acc, option) => {
      const id = getOptionId(option).toString();
      acc[id] = {
        id,
        label: extractLabel(option),
        value: extractValue(option),
        option
      };
      return acc;
    }, {});
  }, [options]);

  const identifiedOptionsValues = Object.values(identifiedOptions);
  const hasExactMatch =
    internalInputValue &&
    identifiedOptionsValues.some(
      (option: IdentifiedOption) =>
        option.label.toLowerCase() === internalInputValue.toLowerCase()
    );

  const filteredOptions =
    !internalInputValue || hasExactMatch
      ? identifiedOptionsValues
      : identifiedOptionsValues.filter((option: IdentifiedOption) => {
          if (!option.label) return false;
          return option.label
            .toLowerCase()
            .includes(internalInputValue.toLowerCase());
        });

  return (
    <div className={className}>
      <Popover open={open} onOpenChange={setOpen}>
        <Command shouldFilter={false}>
          <PopoverAnchor asChild>
            <CommandPrimitive.Input
              asChild
              value={internalInputValue}
              onValueChange={onInputChange}
              onKeyDown={e => setOpen(e.key !== 'Escape')}
              onMouseDown={() => setOpen(open => !!inputValue || !open)}
              onFocus={() => {
                setOpen(true);
              }}
              onBlur={onInputBlur}
            >
              {renderInput ? (
                renderInput({
                  placeholder,
                  onChange: (e: any) => {
                    if (onInputChange) onInputChange(e.target.value);
                    setInternalInputValue(e.target.value);
                    if (freeText) {
                      if (onValueChange) onValueChange(e.target.value);
                    }
                  }
                })
              ) : (
                <Input
                  disabled={disabled}
                  onChange={e => {
                    if (onInputChange) onInputChange(e.target.value);
                    setInternalInputValue(e.target.value);
                    if (freeText) {
                      if (onValueChange) onValueChange(e.target.value);
                    }
                  }}
                  value={internalInputValue}
                  className={`${className} ${inputClassName} disabled:cursor-default`}
                  placeholder={placeholder}
                />
              )}
            </CommandPrimitive.Input>
          </PopoverAnchor>
          {!open && <CommandList aria-hidden='true' className='hidden' />}
          <PopoverContent
            asChild
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute('cmdk-input')
              ) {
                e.preventDefault();
              }
            }}
            className='w-[--radix-popover-trigger-width] p-0'
          >
            <CommandList>
              {isLoading && (
                <CommandPrimitive.Loading className='overflow-hidden'>
                  <div className='py-2 flex justify-center'>
                    <Loader2Icon className='animate-spin' />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {filteredOptions.length > 0 && !isLoading ? (
                <CommandGroup>
                  {filteredOptions.map((opt, index) => {
                    const { id, label, option } = opt;
                    const isSelected =
                      id.toString() === selectedOptionId?.toString();
                    const defaultItemProps = {
                      value: opt.value,
                      onMouseDown: (e: React.MouseEvent<HTMLDivElement>) =>
                        e.preventDefault(),
                      onSelect: () => {
                        setSelectedOptionId(isSelected ? undefined : id);
                      }
                    };
                    return renderOption ? (
                      <CommandItem
                        key={id}
                        disabled={getOptionDisabled?.(option)}
                        asChild
                        className='cursor-pointer'
                        {...defaultItemProps}
                      >
                        {renderOption(option, {
                          isSelected,
                          index
                        })}
                      </CommandItem>
                    ) : (
                      <CommandItem
                        disabled={getOptionDisabled?.(option)}
                        key={id}
                        className='cursor-pointer'
                        {...defaultItemProps}
                      >
                        {label}
                        <Check
                          className={cn(
                            'mr-1 h-4 w-4 ml-auto',
                            isSelected ? 'opacity-100' : 'opacity-0'
                          )}
                        />
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              ) : null}
              {!isLoading ? (
                <CommandEmpty>{emptyMessage ?? 'No options.'}</CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
}

AutoComplete.displayName = 'AutoComplete';

export type { AutoCompleteProps, OptionType };
