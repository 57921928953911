/* eslint-disable no-console */
import axios, { endpoints } from '@utils/axios';
import {
  CommisionCreateRequest,
  OtherCostRequest,
  UpdateCommisionRequest,
  UpdateFoodCostRequest
} from '@utils/interfaces';

export const ManagementService = {
  CreateCommission: async (data: CommisionCreateRequest): Promise<any> => {
    try {
      const response = await axios.post(
        endpoints.MANAGEMENT.CREATE_COMMISSIONS,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  getAllCommission: async (
    page: number,
    take: number,
    brand_ids?: number[]
  ) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.GET_COMMISSIONS, {
        params: { page, take, brand_ids }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  UpdateCommission: async (
    data: UpdateCommisionRequest,
    id: number
  ): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.UPDATE_COMMISSION.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error: any) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  DeleteCommission: async (id: number): Promise<any[]> => {
    try {
      const response = await axios.delete(
        endpoints?.MANAGEMENT.DELETE_COMMISSION.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error in delete client', error);
      throw error;
    }
  },
  getAllFoodCost: async (page: number, take: number, id?: number[]) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.GET_FOOD_COST, {
        params: { page, take, id }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  UpdateFoodCost: async (
    data: UpdateFoodCostRequest,
    id: number
  ): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.UPDATE_FOOD_COST.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error: any) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  getAllOtherCost: async (page: number, take: number, brand_ids?: number[]) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.OTHER_COST, {
        params: { page, take, brand_ids }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  AddOtherCost: async (data: OtherCostRequest): Promise<any[]> => {
    try {
      const response = await axios.post(endpoints?.MANAGEMENT.OTHER_COST, data);
      return response.data;
    } catch (error) {
      console.error('Error in Create Client', error);
      throw error;
    }
  },
  UpdateOtherCost: async (data: OtherCostRequest, id: number): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.OTHER_COST_DETAILS.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error: any) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  DeleteOtherCost: async (id: number): Promise<any[]> => {
    try {
      const response = await axios.delete(
        endpoints?.MANAGEMENT.OTHER_COST_DETAILS.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error in delete client', error);
      throw error;
    }
  },

  fetchSubscriptionStatus: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.status);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      throw error;
    }
  },

  fetchCreditNotes: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.creditnotes);
      return response.data;
    } catch (error) {
      console.error('Error fetching credit notes:', error);
      throw error;
    }
  },

  fetchSubscriptionPortal: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.portal);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription portal:', error);
      throw error;
    }
  },
  fetchCoFunding: async (
    page: number,
    take: number,
    channels?: string[],
    brandIds?: number[],
    locationIds?: number[]
  ) => {
    const nextPage = page + 1;
    try {
      const response = await axios.get(endpoints.CO_FUNDING.GET, {
        params: {
          page: nextPage,
          take,
          channels,
          brandIds,
          locationIds
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching co-funding campaigns:', error);
      throw error;
    }
  },

  fetchCoFundingBranches: async (
    startDate: string,
    endDate: string,
    channel: string,
    brandId: number,
    page: number,
    take: number
  ) => {
    const nextPage = page + 1;
    try {
      const response = await axios.get(endpoints.CO_FUNDING.GET_BRANCHES, {
        params: {
          startDate,
          endDate,
          channel,
          brandId,
          page: nextPage,
          take
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching co-funding campaigns:', error);
      throw error;
    }
  },

  createCoFunding: async (data: {
    startDate: string;
    endDate: string;
    brandId: number;
    branchIds: number[];
    channel: string;
    type: string;
    value: number;
  }) => {
    try {
      const response = await axios.post(endpoints.CO_FUNDING.CREATE, data);
      return response.data;
    } catch (error) {
      console.error('Error creating co-funding campaign:', error);
      throw error;
    }
  },

  deleteCoFunding: async (id: number) => {
    try {
      const response = await axios.delete(
        endpoints.CO_FUNDING.DELETE.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting co-funding campaign:', error);
      throw error;
    }
  }
};
