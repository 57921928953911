import React, { forwardRef } from 'react';

export interface SvgColorProps extends React.HTMLAttributes<HTMLSpanElement> {
  src: string;
}

export const SvgColor = forwardRef<HTMLSpanElement, SvgColorProps>(
  function SvgColor({ src, className, style, ...other }, ref) {
    const mergedStyle = {
      ...style,
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`
    };

    return (
      <span
        ref={ref}
        className={`inline-block w-6 h-6 bg-current ${className ?? ''}`}
        style={mergedStyle}
        {...other}
      />
    );
  }
);

SvgColor.displayName = 'SvgColor';
export default SvgColor;
