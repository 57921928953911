import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListItemAvatarProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ListItemAvatar = React.forwardRef<HTMLDivElement, ListItemAvatarProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex h-9 w-9 shrink-0 items-center justify-center overflow-hidden rounded-full',
          'mr-0',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

ListItemAvatar.displayName = 'ListItemAvatar';

export { ListItemAvatar };
export default ListItemAvatar;
