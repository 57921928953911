/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// routes
import { paths } from '@routes/paths';
import { RequiredPerm } from '@routes/permissions';
// utils
import { useAuthContext } from '@context/auth/use-auth-context';
import { useRouter } from '@hooks/routes';
// api
import {
  fetchAreas,
  fetchBrands,
  fetchChannels,
  fetchCities,
  fetchCuisines,
  fetchLocations
} from '@api/index';
// actions
import {
  setAreas,
  setBrands,
  setChannels,
  setCities,
  setCuisines,
  setLocations
} from '@store/slices/FilterSlice';
import { ChannelType } from '@utils/enums/agregators/channel-types';
import { CountryName } from '@utils/enums/countries/country-names';
import { getClientCountry } from '@utils/helpers';
import { RoleType } from '@utils/roles';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { user, authenticated } = useAuthContext();
  const isGrowIntelEnable =
    user?.roles?.includes(RoleType.MANAGER) ||
    user?.features?.some((perm: string) =>
      RequiredPerm?.GrowIntel?.includes(perm)
    );

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      router.replace(paths?.auth?.jwt?.login);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const safelyFetch = async (
        fetchFn: () => Promise<any>,
        dispatchFn: any,
        defaultValue = []
      ) => {
        try {
          const data = await fetchFn();
          dispatch(dispatchFn(data));
        } catch (error) {
          console.error(`Error fetching data for ${fetchFn.name}:`, error);
          dispatch(dispatchFn(defaultValue));
        }
      };

      await Promise.all([
        safelyFetch(fetchChannels, setChannels),
        safelyFetch(fetchLocations, setLocations),
        safelyFetch(
          () =>
            fetchCities(
              isGrowIntelEnable,
              getClientCountry() === CountryName.ROMANIA
                ? ChannelType.Glovo
                : undefined
            ),
          setCities
        ),
        safelyFetch(() => fetchAreas(isGrowIntelEnable), setAreas),
        safelyFetch(
          () =>
            fetchCuisines(
              isGrowIntelEnable,
              getClientCountry() === CountryName.ROMANIA
                ? ChannelType.Glovo
                : undefined
            ),
          setCuisines
        ),
        safelyFetch(fetchBrands, setBrands)
      ]);
    };
    fetchData();
  }, [authenticated, dispatch, isGrowIntelEnable, user]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
