import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from '@growdash/design-system/molecules';
import { AuthModernCompactLayout } from '../../layouts';
// paths
import { paths } from '../paths';

// Pages
const LoginPage = lazy(() => import('@pages/auth/LoginPage'));
const ResetPasswordPage = lazy(() => import('@pages/auth/ResetPasswordPage'));
const ForgotPasswordPage = lazy(
  () => import('@pages/auth/forgot-password-page')
);

const authJwt = {
  path: '',
  element: (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: paths?.auth?.jwt?.login,
      element: (
        <AuthModernCompactLayout>
          <LoginPage />
        </AuthModernCompactLayout>
      )
    },
    {
      path: paths?.auth?.jwt?.reset_password,
      element: (
        <AuthModernCompactLayout>
          <ResetPasswordPage />
        </AuthModernCompactLayout>
      )
    },
    {
      path: paths?.auth?.jwt?.forgot_password,
      element: (
        <AuthModernCompactLayout>
          <ForgotPasswordPage />
        </AuthModernCompactLayout>
      )
    }
  ]
};

export const authRoutes = [
  {
    path: '',
    children: [authJwt]
  }
];
