import {
  AuthApiFactory,
  Configuration,
  UsersApiFactory
} from '@growdash/identity-client/generated';

export const authAPIBasePath =
  import.meta.env.VITE_IDENTITY_API_URL ??
  'https://identity.dev.mygrowdash.com';

const baseConfig = {
  basePath: authAPIBasePath
};

const apiQueryConfig = new Configuration(baseConfig);

export let identityClient = {
  authAPI: AuthApiFactory(apiQueryConfig),
  usersAPI: UsersApiFactory(apiQueryConfig)
};

export const authorizeIdentityService = (accessToken: string | null) => {
  if (accessToken) {
    const config = { ...baseConfig, accessToken };
    identityClient.authAPI = AuthApiFactory(new Configuration(config));
    identityClient.usersAPI = UsersApiFactory(new Configuration(config));
  } else {
    delete apiQueryConfig.accessToken;
  }
};
