import { memo } from 'react';

//
import BackgroundShape from './background-shape';

// ----------------------------------------------------------------------

function CampaignSuccessIllustration({ ...other }: any) {
  // const theme = useTheme();

  // const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <svg
      className='h-3/4 w-3/4'
      fill='none'
      viewBox='0 0 480 360'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <BackgroundShape />

      <image
        href='/assets/illustrations/characters/character_2.png'
        height='300'
        x='310'
        y='30'
      />
      <image href='/assets/create_ads.png' height='180' x='80' y='80' />

      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='65'
        height='65'
        viewBox='0 0 58 58'
        fill='none'
        x='175'
        y='185'
      >
        <path
          d='M28.859 57.7181C44.7974 57.7181 57.718 44.7974 57.718 28.859C57.718 12.9206 44.7974 0 28.859 0C12.9206 0 0 12.9206 0 28.859C0 44.7974 12.9206 57.7181 28.859 57.7181Z'
          fill='white'
        />
        <path
          d='M22.0021 42.0014L11.8799 30.837C11.5064 30.425 11.3116 29.8817 11.3384 29.3262C11.3651 28.7708 11.6113 28.2487 12.0227 27.8746L14.8352 25.3191C15.2471 24.9455 15.7905 24.7507 16.3459 24.7775C16.9014 24.8043 17.4235 25.0504 17.7976 25.4618L23.8937 32.1719L40.6831 16.5603C41.0905 16.1823 41.6311 15.9813 42.1864 16.0014C42.7418 16.0214 43.2665 16.261 43.6455 16.6674L46.2296 19.4513C46.4176 19.6526 46.5641 19.889 46.6605 20.147C46.7569 20.405 46.8015 20.6795 46.7915 20.9548C46.7816 21.23 46.7173 21.5006 46.6025 21.751C46.4877 22.0014 46.3246 22.2266 46.1225 22.4137L24.9502 42.1013C24.7506 42.2894 24.5158 42.436 24.2592 42.5329C24.0026 42.6297 23.7294 42.6748 23.4553 42.6655C23.1812 42.6562 22.9117 42.5927 22.6623 42.4787C22.4129 42.3648 22.1885 42.2025 22.0021 42.0014Z'
          fill='url(#paint0_linear_1059_15335)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_1059_15335'
            x1='11.3359'
            y1='16'
            x2='11.3359'
            y2='42.6667'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#5BE39A' />
            <stop offset='1' stopColor='#007867' />
          </linearGradient>
        </defs>
      </svg>
    </svg>
  );
}

export default memo(CampaignSuccessIllustration);
