import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, children, disabled, selected, ...props }, ref) => {
    return (
      <li
        ref={ref}
        className={cn(
          'relative flex w-full cursor-default select-none items-center gap-1',
          'rounded-sm py-2 text-sm outline-none',
          'transition-colors hover:bg-accent hover:text-accent-foreground',
          'focus:bg-accent focus:text-accent-foreground',
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          selected && 'bg-accent text-accent-foreground',
          className
        )}
        data-disabled={disabled}
        {...props}
      >
        {children}
      </li>
    );
  }
);
ListItem.displayName = 'ListItem';

export { ListItem };
export default ListItem;
