import { cva, type VariantProps } from 'class-variance-authority';
import {
  CheckIcon,
  ChevronDown,
  ChevronRight,
  WandSparkles,
  XCircle,
  XIcon
} from 'lucide-react';
import * as React from 'react';

import {
  Badge,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator
} from '../../atoms';

import { cn } from '../../../../lib/utils';

/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const GroupedMultiSelectVariants = cva('m-1', {
  variants: {
    variant: {
      default: 'border-foreground/10 text-foreground bg-card hover:bg-card/80',
      secondary:
        'border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80',
      destructive:
        'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
      inverted: 'inverted'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

export type SubOption = {
  id: number;
  name: string;
};

export type GroupedMultiSelectOption = {
  value: any;
  label: string;
  icon?: React.ComponentType<{ className?: string }>;
  options?: SubOption[];
};

export interface GroupedMultiSelectHandle {
  clear: () => void;
}

interface GroupedMultiSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof GroupedMultiSelectVariants> {
  options: GroupedMultiSelectOption[];
  onValueChange: (value: any[]) => void;
  defaultValue?: string[];
  value?: string[];
  placeholder?: string;
  animation?: number;
  maxCount?: number;
  modalPopover?: boolean;
  asChild?: boolean;
  className?: string;
  popoverAlign?: 'start' | 'center' | 'end';
  showSelectAll?: boolean;
  clipPillText?: boolean;
  clipPillLength?: number;
  wrapSelectedOptions?: boolean;
  error?: any;
}

export const GroupedMultiSelect = React.forwardRef<
  GroupedMultiSelectHandle,
  GroupedMultiSelectProps
>(
  (
    {
      options,
      onValueChange,
      variant,
      defaultValue = [],
      value,
      placeholder = 'Select options',
      animation = 0,
      maxCount = 3,
      modalPopover = false,
      popoverAlign = 'start',
      className,
      showSelectAll = false,
      clipPillText = true,
      clipPillLength = 12,
      wrapSelectedOptions = false,
      error,
      ...props
    },
    ref
  ) => {
    const [selectedValues, setSelectedValues] =
      React.useState<string[]>(defaultValue);
    const [expandedGroups, setExpandedGroups] = React.useState<string[]>(() => {
      // Initialize with first group if it exists and has suboptions
      if (options[0] && options[0].options && options[0].options.length > 0) {
        return [options[0].value];
      }
      return [];
    });
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const isControlled = value !== undefined;
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [isAnimating, setIsAnimating] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
      clear: () => {
        setSelectedValues([]);
        onValueChange([]);
      }
    }));

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        setIsPopoverOpen(true);
      } else if (event.key === 'Backspace' && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (optionValue: string | number) => {
      const newSelectedValues = selectedValues?.includes(String(optionValue))
        ? selectedValues.filter(value => value !== String(optionValue))
        : [...selectedValues, String(optionValue)];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleGroup = (groupValue: string) => {
      setExpandedGroups(prev =>
        prev.includes(groupValue)
          ? prev.filter(v => v !== groupValue)
          : [...prev, groupValue]
      );
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen(prev => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options?.map(option => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    const handleSearch = (value: string) => {
      setSearchQuery(value);
      if (value) {
        // Expand all groups when searching
        const allGroupValues = options
          .filter(option => option.options && option.options.length > 0)
          .map(option => option.value);
        setExpandedGroups(allGroupValues);
      } else {
        // Collapse all groups when search is cleared
        setExpandedGroups([]);
      }
    };

    const actualValues = isControlled ? value : selectedValues;

    const renderOption = (option: GroupedMultiSelectOption) => {
      const isExpanded = expandedGroups.includes(option.value);
      const hasSubOptions = option.options && option.options.length > 0;

      // Filter sub-options based on search query
      const filteredSubOptions = option.options?.filter(subOption =>
        subOption.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // If there's a search query and no matching sub-options, don't render the parent
      if (searchQuery && hasSubOptions && !filteredSubOptions?.length) {
        return null;
      }

      const getCheckboxState = () => {
        if (hasSubOptions) {
          return option.options?.every((subOpt: SubOption) =>
            actualValues?.includes(String(subOpt.id))
          )
            ? 'bg-primary text-primary-foreground'
            : 'opacity-50 [&_svg]:invisible';
        }
        return actualValues?.includes(option.value)
          ? 'bg-primary text-primary-foreground'
          : 'opacity-50 [&_svg]:invisible';
      };

      return (
        <React.Fragment key={option.value}>
          <CommandItem
            onSelect={() => {
              if (hasSubOptions) {
                toggleGroup(option.value);
              }
            }}
            className={cn('cursor-default', hasSubOptions && 'font-medium')}
          >
            <div className='flex items-center w-full justify-between'>
              <div className='flex items-center'>
                <button
                  type='button'
                  role='checkbox'
                  aria-checked={
                    hasSubOptions
                      ? option.options?.every(subOpt =>
                          actualValues?.includes(String(subOpt.id))
                        )
                      : actualValues?.includes(option.value)
                  }
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    getCheckboxState()
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    if (hasSubOptions) {
                      const subOptionValues =
                        option.options?.map(subOpt => String(subOpt.id)) || [];
                      const allSubOptionsSelected = subOptionValues.every(val =>
                        actualValues?.includes(val)
                      );

                      const newSelectedValues = allSubOptionsSelected
                        ? actualValues?.filter(
                            val => !subOptionValues.includes(val)
                          )
                        : [...(actualValues || []), ...subOptionValues];

                      setSelectedValues(newSelectedValues);
                      onValueChange(newSelectedValues);
                    } else {
                      toggleOption(option.value);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      if (hasSubOptions) {
                        const subOptionValues =
                          option.options?.map(subOpt => String(subOpt.id)) ||
                          [];
                        const allSubOptionsSelected = subOptionValues.every(
                          val => actualValues?.includes(val)
                        );

                        const newSelectedValues = allSubOptionsSelected
                          ? actualValues?.filter(
                              val => !subOptionValues.includes(val)
                            )
                          : [...(actualValues || []), ...subOptionValues];

                        setSelectedValues(newSelectedValues);
                        onValueChange(newSelectedValues);
                      } else {
                        toggleOption(option.value);
                      }
                    }
                  }}
                >
                  <CheckIcon className='h-4 w-4' />
                </button>

                {option.icon && (
                  <option.icon className='mr-2 h-4 w-4 text-muted-foreground' />
                )}
                <span>{option.label}</span>
              </div>
              {hasSubOptions && (
                <ChevronRight
                  className={cn(
                    'h-4 w-4 mr-2 transition-transform',
                    isExpanded && 'rotate-90'
                  )}
                />
              )}
            </div>
          </CommandItem>
          {hasSubOptions && isExpanded && (
            <div className='ml-4 border-l pl-4'>
              {(searchQuery ? filteredSubOptions : option.options)?.map(
                subOption => (
                  <CommandItem
                    key={subOption.id}
                    onSelect={() => {}}
                    className='cursor-default'
                  >
                    <button
                      type='button'
                      role='checkbox'
                      aria-checked={actualValues?.includes(
                        String(subOption.id)
                      )}
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        actualValues?.includes(String(subOption.id))
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        toggleOption(subOption.id);
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          toggleOption(subOption.id);
                        }
                      }}
                    >
                      <CheckIcon className='h-4 w-4' />
                    </button>
                    <span>{subOption.name}</span>
                  </CommandItem>
                )
              )}
            </div>
          )}
        </React.Fragment>
      );
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            {...props}
            onClick={handleTogglePopover}
            className={cn(
              'flex min-h-9 w-full rounded-md border border-input px-0 text-base items-center justify-between bg-inherit hover:bg-inherit [&_svg]:pointer-events-auto',
              className,
              wrapSelectedOptions && actualValues?.length > 0
                ? 'h-auto'
                : 'min-h-9'
            )}
          >
            {actualValues?.length > 0 ? (
              <div className='flex justify-between items-center w-full'>
                <div
                  style={{
                    flexWrap: wrapSelectedOptions ? 'wrap' : 'nowrap'
                  }}
                  className='flex items-center overflow-x-scroll scrollbar-hidden'
                >
                  {actualValues.slice(0, maxCount).map(value => {
                    const parentOption = options.find(o =>
                      o.options?.some(so => String(so.id) === value)
                    );
                    const subOption = parentOption?.options?.find(
                      so => String(so.id) === value
                    );
                    const displayText = subOption
                      ? `${parentOption?.label} - ${subOption.name}`
                      : parentOption?.label || value;

                    return (
                      <Badge
                        key={value}
                        className={cn(
                          isAnimating ? 'animate-bounce duration-100' : '',
                          GroupedMultiSelectVariants({ variant })
                        )}
                      >
                        {parentOption?.icon && (
                          <parentOption.icon className='h-4 w-4 hidden mr-2' />
                        )}
                        {clipPillText &&
                        displayText &&
                        displayText.length > clipPillLength
                          ? `${displayText.slice(0, clipPillLength)}...`
                          : displayText}
                        <XCircle
                          size={12}
                          color='#333'
                          className='mx-1 cursor-pointer'
                          onClick={event => {
                            event.stopPropagation();
                            toggleOption(value);
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {actualValues?.length > maxCount && (
                    <Badge
                      className={cn(
                        'bg-transparent text-foreground border-foreground/1 hover:bg-transparent',
                        isAnimating ? 'animate-bounce duration-100' : '',
                        GroupedMultiSelectVariants({ variant })
                      )}
                    >
                      {`+ ${actualValues?.length - maxCount} `}
                      <XCircle
                        color='#333'
                        size={12}
                        className='mx-1 cursor-pointer'
                        onClick={event => {
                          event.stopPropagation();
                          clearExtraOptions();
                        }}
                      />
                    </Badge>
                  )}
                </div>
                <div className='flex items-center justify-between'>
                  <XIcon
                    className='h-4 mx-2 cursor-pointer text-muted-foreground'
                    onClick={event => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation='vertical'
                    className='flex min-h-4 h-full'
                  />
                  <ChevronDown className='h-4 mx-2 cursor-pointer text-muted-foreground' />
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-between w-full mx-auto'>
                <span
                  className={cn(
                    'text-sm text-muted-foreground mx-3 font-normal',
                    error ? 'text-red-500' : ''
                  )}
                >
                  {placeholder}
                </span>
                <ChevronDown className='h-4 cursor-pointer text-muted-foreground mx-2' />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className='w-[var(--radix-popover-trigger-width)] p-0'
          align={popoverAlign}
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <CommandInput
              placeholder='Search...'
              onKeyDown={handleInputKeyDown}
              onValueChange={handleSearch}
              value={searchQuery}
            />
            <div className='max-h-[300px] overflow-y-auto'>
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {showSelectAll && (
                    <CommandItem
                      key='all'
                      onSelect={() => {}}
                      className='cursor-default'
                    >
                      <button
                        type='button'
                        role='checkbox'
                        aria-checked={actualValues?.length === options.length}
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          actualValues?.length === options.length
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible'
                        )}
                        onClick={e => {
                          e.stopPropagation();
                          toggleAll();
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            toggleAll();
                          }
                        }}
                      >
                        <CheckIcon className='h-4 w-4' />
                      </button>
                      <span>(Select All)</span>
                    </CommandItem>
                  )}
                  {options?.map(renderOption)}
                </CommandGroup>
              </CommandList>
            </div>
            <CommandSeparator />
            <CommandGroup>
              <div className='flex items-center justify-between'>
                {actualValues?.length > 0 && (
                  <>
                    <CommandItem
                      onSelect={handleClear}
                      className='flex-1 justify-center cursor-pointer'
                    >
                      Clear
                    </CommandItem>
                    <Separator
                      orientation='vertical'
                      className='flex min-h-4 h-full'
                    />
                  </>
                )}
                <CommandItem
                  onSelect={() => setIsPopoverOpen(false)}
                  className='flex-1 justify-center cursor-pointer max-w-full'
                >
                  Close
                </CommandItem>
              </div>
            </CommandGroup>
          </Command>
        </PopoverContent>
        {animation > 0 && actualValues?.length > 0 && (
          <WandSparkles
            className={cn(
              'cursor-pointer text-foreground bg-background w-3 h-3',
              isAnimating ? '' : 'text-muted-foreground'
            )}
            onClick={() => setIsAnimating(!isAnimating)}
          />
        )}
      </Popover>
    );
  }
);

GroupedMultiSelect.displayName = 'GroupedMultiSelect';
