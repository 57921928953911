import { useAuthContext } from '@context/auth/use-auth-context.ts';
import {
  Alert,
  SidebarInset,
  SidebarProvider
} from '@growdash/design-system/atoms';
import '@growdash/design-system/globals.css';
import {
  ChangeUserProfile,
  ResetPasswordDialog
} from '@growdash/design-system/molecules';
import { usePathname, useRouter } from '@hooks/routes';
import { useJune } from '@hooks/use-june.ts';
import { useNavData } from '@hooks/use-nav-data.tsx';
import { identityClient } from '@lib/identity.ts';
import { ManagementService } from '@modules/management/api';
import { paths } from '@routes/paths.ts';
import { RoleType } from '@utils/roles.ts';
import { clearSession, setSession } from '@utils/session.ts';
import { enqueueSnackbar } from 'notistack';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Header from './header.tsx';
import { AppSidebar } from '../app-sidebar';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

type NavItem = {
  title: string;
  path: string;
  icon?: React.ReactElement;
  permissions: string[];
  hasChildren?: boolean;
};

export default function DashboardLayout({ children }: Props) {
  const router = useRouter();
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(
    null
  );
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState(false);
  const [isOpenChangeUserNameModal, setIsOpenChangeUserNameModal] =
    useState(false);
  const [allClients, setAllClients] = useState<any[]>([]);
  const { user, logout } = useAuthContext();

  const { authAPI, usersAPI } = identityClient;

  const navData = useNavData();
  const juneHook = useJune('q3ClmW5XJpuu6QHF') as any;
  const pathname = usePathname();

  const handleResetFilters = useCallback(() => {
    const path = pathname || window.location.pathname;
    router.push(path);
  }, [pathname, router]);

  const filterNavDataByPermissions = () => {
    return navData
      .map(section => ({
        ...section,
        items: (section.items as NavItem[]).filter(
          item =>
            item?.permissions?.some(permission =>
              user?.permissions?.includes(permission)
            ) ?? false
        )
      }))
      .filter(section => section.items.length > 0);
  };

  const fetchSubscriptionStatus = async () => {
    try {
      const subResponse = await ManagementService.fetchSubscriptionStatus();
      setSubscriptionStatus(subResponse.status);
    } catch (error) {
      enqueueSnackbar(`Error fetching subscription status: ${error}`, {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    fetchSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    ReactGA.set({ userId: null });
    logout();
    posthog.reset();
    clearSession();
    router.push(paths?.auth?.jwt?.login);
  };
  const getClients = useCallback(async () => {
    try {
      if (user?.roles?.includes(RoleType.MANAGER)) {
        // const response = await axiosInstance.get(
        //   endpoints.AUTH.GET_CSM_CLIENTS
        // );
        const response =
          await usersAPI.usersControllerGetClientsForCustomerSuccessUser();
        setAllClients(response.data);
      } else {
        // const response = await axiosInstance.get(
        //   endpoints.AUTH.GET_ALL_CLIENTS
        // );
        const response = await usersAPI.usersControllerGetClientsForClients();
        setAllClients(response.data);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToken = useCallback(
    async (id: number) => {
      handleResetFilters();
      try {
        const response = await authAPI.authControllerRegenerateToken({
          newClientId: id
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token } = response.data;

        if (access_token) {
          const res = await usersAPI.usersControllerGetUserInfo({
            headers: { Authorization: `Bearer ${access_token}` }
          });
          const tokenUser = res.data;
          await setSession(access_token as any);
          if (juneHook) {
            juneHook.identify(tokenUser?.username?.toLowerCase(), {
              email: tokenUser?.username,
              CSUser: tokenUser?.name,
              country: tokenUser?.country.name,
              roles: tokenUser?.roles
            });
            juneHook.track(
              'CS visited Client',
              {
                visitedClient: tokenUser.client.name,
                CS_user: tokenUser.name
              },
              {
                // Add the GROUP_ID here to track this event on behalf of a workspace
                context: {
                  groupId: `Customer Service`
                }
              }
            );
          }
          window.location.reload();
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, handleResetFilters, juneHook]
  );

  const handleChangePassword = () => {
    setIsOpenChangePasswordModal(true);
  };

  const submitNewPassword = async (
    newPassword: string,
    oldPassword: string
  ) => {
    try {
      await identityClient.authAPI.authControllerChangePassword({
        oldPassword,
        newPassword
      });

      enqueueSnackbar('Password changed successfully', { variant: 'success' });
      setIsOpenChangePasswordModal(false);
    } catch (error) {
      enqueueSnackbar(error?.message || 'Failed to change password', {
        variant: 'error'
      });
      setIsOpenChangePasswordModal(false);
    } finally {
      setIsOpenChangePasswordModal(false);
    }
  };

  const handleChangeUserName = () => {
    setIsOpenChangeUserNameModal(true);
  };

  const handleCreditNotes = () => {
    router.push(paths.management.credit_notes);
  };

  const submitNewUserCredential = async (name: string) => {
    try {
      await identityClient.usersAPI.usersControllerUpdateUser(user?.sub, {
        fullName: name,
        email: user?.username
      });
    } catch (error) {
      enqueueSnackbar(error?.message || 'Failed to update user credentials', {
        variant: 'error'
      });
    } finally {
      enqueueSnackbar('User credentials updated successfully', {
        variant: 'success'
      });
      setIsOpenChangeUserNameModal(false);
    }
  };

  const filteredNavData = filterNavDataByPermissions();
  return (
    <>
      <ChangeUserProfile
        isOpen={isOpenChangeUserNameModal}
        setIsOpen={setIsOpenChangeUserNameModal}
        handleSubmit={submitNewUserCredential}
        userName={user?.name}
        email={user?.username}
      />
      <ResetPasswordDialog
        isOpen={isOpenChangePasswordModal}
        setIsOpen={setIsOpenChangePasswordModal}
        handleSubmit={submitNewPassword}
      />
      <SidebarProvider>
        <AppSidebar
          user={user}
          handleLogout={handleLogout}
          navDatas={filteredNavData}
          activeTab={pathname}
          handleChangePassword={handleChangePassword}
          handleChangeUserName={handleChangeUserName}
          handleCreditNotes={handleCreditNotes}
          isManager={user?.roles?.includes(RoleType.MANAGER)}
        />
        <SidebarInset>
          <Header
            handleChangeToken={handleChangeToken}
            clients={allClients}
            navDatas={filteredNavData}
          />
          {subscriptionStatus === 'unpaid' && (
            <Alert variant='warning'>
              Your subscription payment is pending. Please check your payment
              method.
            </Alert>
          )}
          <div className='p-4 container mx-auto pb-20'>{children}</div>
        </SidebarInset>
      </SidebarProvider>
    </>
  );
}
