// eslint-disable-next-line import/no-extraneous-dependencies
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// analytics connect
import { PostHogWProvider } from '@integrations/postHog/PostHogContext';
import '@integrations/sentry.js';
// App entry point & Redux store
import { store } from '@store/index';
import App from './App';

// Import i18n.ts
import './integrations/i18n';

// Import global styles
import '@growdash/design-system/globals.css';

// ----------------------------------------------------------------------

const root = createRoot(document.getElementById('root') as HTMLElement);
ReactGA.initialize('G-DH5CL15V94');
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <PostHogWProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </PostHogWProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
};
