import { UserInfoDto } from '@growdash/identity-client/generated';
import { getSessionUser } from './session';

export const currencyString: () => string | undefined = () => {
  const user: UserInfoDto = getSessionUser();
  return user?.country?.currency;
};

export const getClientCountry: () => string | undefined = () => {
  const user: UserInfoDto = getSessionUser();
  return user?.country?.name;
};

export const filterEmpties = (obj: any, eliminateZeros = false) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (
      newObj[key] === '' ||
      newObj[key] === undefined ||
      newObj[key] === null ||
      (typeof newObj[key] === 'number' && Number.isNaN(newObj[key])) ||
      (Array.isArray(newObj[key]) && newObj[key].length === 0) ||
      (eliminateZeros && newObj[key] === 0)
    ) {
      delete newObj[key];
    }
  });
  return newObj;
};
