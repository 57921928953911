import { m } from 'framer-motion';
import * as React from 'react';
// @ts-ignore
import shinyGif from '../../../../public/animations/shiny.gif';
import { cn } from '../../../lib/utils';
import { Button } from '../../atoms';
import { type ButtonProps } from '../../atoms/button';

interface ShinyButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const ShinyButton = React.forwardRef<
  HTMLButtonElement,
  ShinyButtonProps
>(({ className, children, disabled, ...props }, ref) => {
  return (
    <m.div initial="hidden" animate="visible" exit="exit">
      <Button
        ref={ref}
        disabled={disabled}
        className={cn(
          'relative overflow-hidden text-white bg-gradient-to-b from-[#38339E] to-[#070086] border-2 border-[#213A9870] hover:opacity-90 transition-opacity',
          className
        )}
        {...props}
      >
        <img
          className="w-fit h-fit absolute"
          src={shinyGif}
          alt="shiny-button"
        />
        {children}
      </Button>
    </m.div>
  );
});

ShinyButton.displayName = 'ShinyButton';
