import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  children: React.ReactNode;
}

const List = React.forwardRef<HTMLUListElement, ListProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <ul
        ref={ref}
        className={cn(
          'min-w-[200px] overflow-hidden rounded-md bg-popover p-1',
          className
        )}
        {...props}
      >
        {children}
      </ul>
    );
  }
);
List.displayName = 'List';

export { List };
export default List;
