import { useCallback, useState } from 'react';
import { Button } from '../button';
import { SvgColor } from '../svg-color';

// ----------------------------------------------------------------------

export default function FullScreenOption() {
  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  }, []);

  return (
    <div className='p-2.5'>
      <Button
        onClick={onToggleFullScreen}
        variant='outline'
        className={`
          w-full h-12 flex items-center justify-center gap-1
          border border-border/10 rounded-md
          text-muted-foreground font-medium
          transition-colors duration-200
          hover:bg-accent hover:text-accent-foreground
          ${fullscreen ? 'text-foreground' : ''}
        `}
      >
        <SvgColor
          src={`/assets/icons/setting/${
            fullscreen ? 'ic_exit_full_screen' : 'ic_full_screen'
          }.svg`}
          className={`
            w-4 h-4
            ${
              fullscreen
                ? 'bg-gradient-to-br from-primary to-primary-foreground'
                : 'bg-gradient-to-br from-muted to-muted-foreground'
            }
          `}
        />
        {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
      </Button>
    </div>
  );
}
