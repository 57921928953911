import * as React from 'react';
import { cn } from '../../../lib/utils';
import { Separator } from './separator';

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  flexItem?: boolean;
}

const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  ({ orientation = 'horizontal', className, ...props }, ref) => {
    return (
      <Separator
        ref={ref}
        decorative
        orientation={orientation}
        className={cn(className)}
        {...props}
      />
    );
  }
);

Divider.displayName = 'Divider';

export default Divider;
