import { usePathname, useRouter } from '@hooks/routes';
import { useMemo } from 'react';

type BreadcrumbItem = {
  title: string;
  link: string;
};

const routeMapping: Record<string, BreadcrumbItem[]> = {
  '/pulse': [{ title: 'Overview', link: '/pulse' }],
  '/pulse/economics': [
    { title: 'Dashboard', link: '/pulse' },
    { title: 'Economics', link: '/pulse/economics' }
  ],
  '/pulse/overview': [
    { title: 'Dashboard', link: '/pulse' },
    { title: 'Overview', link: '/pulse/overview' }
  ],
  '/campaigns': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Campaigns', link: '/campaigns' }
  ],
  '/campaigns/ads': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Campaigns', link: '/campaigns' },
    { title: 'Ads', link: '/campaigns/ads' }
  ],
  '/campaigns/ads/campaign/create': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Campaigns', link: '/campaigns' },
    { title: 'Ads', link: '/campaigns/ads' },
    { title: 'Create Ads', link: '/campaigns/ads/campaign/create' }
  ],
  '/campaigns/ads/campaign/edit': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Campaigns', link: '/campaigns' },
    { title: 'Ads', link: '/campaigns/ads' }
  ],
  '/campaigns/discount': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Campaigns', link: '/campaigns' },
    { title: 'Discount', link: '/campaigns/discount' }
  ],
  '/grow-intel/menu_analysis': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Menu Analysis', link: '/grow-intel/menu_analysis' }
  ],
  '/grow-intel/market_changes': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Market Changes', link: '/grow-intel/market_changes' }
  ],
  '/grow-intel/menu_suggestion': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Menu Suggestion', link: '/grow-intel/menu_suggestion' }
  ],
  '/grow-intel/competitors': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Competitors', link: '/grow-intel/competitors' }
  ],
  '/grow-intel/popular_brands': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Popular Brands', link: '/grow-intel/popular_brands' }
  ],
  '/grow-intel/popular_items': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Popular Items', link: '/grow-intel/popular_items' }
  ],
  '/grow-intel/item_search': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
    { title: 'Item Search', link: '/grow-intel/item_search' }
  ],
  '/operations/performance': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Operations', link: '/operations' },
    { title: 'Performance', link: '/operations/performance' }
  ],
  '/operations/feedback': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Operations', link: '/operations' },
    { title: 'Feedback', link: '/operations/feedback' }
  ],
  '/management/profile': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Management', link: '/management' },
    { title: 'Profile', link: '/management/profile' }
  ],
  '/dashboard': [{ title: 'Dashboard', link: '/dashboard' }],
  '/dashboard/overview_details': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Overview Details', link: '/dashboard/overview_details' }
  ],
  '/dashboard/finance': [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Finance', link: '/dashboard/finance' }
  ]
};

export function useBreadcrumbs() {
  const pathname = usePathname();
  const router = useRouter();

  const breadcrumbs = useMemo(() => {
    if (routeMapping[pathname]) {
      return routeMapping[pathname];
    }

    const segments = pathname.split('/').filter(Boolean);

    // Handle specific route pattern for /campaigns/ads/campaign/edit/{id}
    if (
      segments[0] === 'campaigns' &&
      segments[1] === 'ads' &&
      segments[2] === 'campaign'
    ) {
      return [
        { title: 'Campaigns', link: '/campaigns' },
        { title: 'Ads', link: '/campaigns/ads' },
        { title: 'Edit', link: pathname }
      ];
    }

    // Handle growintel detail route
    if (segments[0] === 'grow-intel' && segments[1] === 'restaurant') {
      return [
        { title: 'Grow Intel', link: '/grow-intel/menu_analysis' },
        { title: 'Restaurant', link: '#', onClick: () => router.back() },
        { title: 'Detail', link: '#' }
      ];
    }

    // Default handling for other routes
    return segments.map((segment, index) => {
      const path = `/${segments.slice(0, index + 1).join('/')}`;
      const isIdOrNumber =
        !isNaN(Number(segment)) || segment.match(/^[0-9a-fA-F-]{36}$/);

      const title = isIdOrNumber
        ? 'Detail'
        : segment.charAt(0).toUpperCase() + segment.slice(1);

      return {
        title,
        link: path
      };
    });
  }, [pathname, router]);

  return breadcrumbs;
}
