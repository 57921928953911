import { RoleType } from '@utils/roles';
import { getSessionUser } from '@utils/session';
import { Navigate, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

interface CheckPermProps {
  children: React.ReactNode;
  permissionRequired: string[];
  isGrowIntelEnable?: boolean;
}

export function CheckPermission({
  children,
  permissionRequired,
  isGrowIntelEnable = false
}: CheckPermProps) {
  const user = getSessionUser();
  const location = useLocation();

  if (
    permissionRequired?.length > 0 &&
    !user?.roles?.includes(RoleType.MANAGER) &&
    !user?.permissions?.some((perm: string) =>
      permissionRequired?.includes(perm)
    ) &&
    !isGrowIntelEnable
  ) {
    return <Navigate to='/unauthorized' state={{ form: location }} replace />;
  }
  return <>{children}</>;
}
