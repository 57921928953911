// eslint-disable-next-line import/no-extraneous-dependencies
import { jwtDecode } from '@utils/jwt';
import { RoleType } from '@utils/roles';
import { getSessionToken } from '@utils/session';
import posthog from 'posthog-js';

const PH_CONFIG = {
  api_host: import.meta.env.VITE_REACT_APP_PH_API_HOST as string,
  person_profiles: 'identified_only'
};

const initPostHog = () => {
  const user = jwtDecode(getSessionToken());

  const isLocalOrTestEnvironment =
    window.location.host.includes('127.0.0.1') ||
    window.location.host.includes('localhost') ||
    window.location.host.includes('dashboard.dev.mygrowdash.com');
  const isInvalidUser =
    user?.username?.endsWith('@mygrowdash.com') ||
    user?.roles?.includes(RoleType.MANAGER);

  if (!isLocalOrTestEnvironment && !isInvalidUser) {
    posthog.init(
      import.meta.env.VITE_REACT_APP_PH_API_KEY as string,
      PH_CONFIG as any
    );
  }

  return posthog;
};

export const posthogInstance = initPostHog();
