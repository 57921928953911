import * as React from 'react';

import { cn } from '../../../lib/utils';

export interface ScrollbarProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const Scrollbar = React.forwardRef<HTMLDivElement, ScrollbarProps>(
  ({ children, className, ...props }, ref) => {
    const userAgent =
      typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    if (isMobile) {
      return (
        <div ref={ref} className={cn('overflow-auto', className)} {...props}>
          {children}
        </div>
      );
    }

    return (
      <div className='h-full w-full flex-grow overflow-hidden'>
        <div
          ref={ref}
          className={cn(
            'h-full max-h-full overflow-auto',
            'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600',
            'hover:scrollbar-thumb-gray-400 dark:hover:scrollbar-thumb-gray-500',
            'scrollbar-thumb-rounded-full',
            className
          )}
          {...props}
        >
          {children}
        </div>
      </div>
    );
  }
);

Scrollbar.displayName = 'Scrollbar';

export default Scrollbar;
