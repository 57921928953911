import * as React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import { cn } from '../../../lib/utils';

interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  href: string;
  className?: string;
  children: React.ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: string;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, href, target, rel, children, ...props }, ref) => {
    // Automatically add noopener noreferrer for security when target is _blank
    const safeRel =
      target === '_blank' ? `noopener noreferrer${rel ? ` ${rel}` : ''}` : rel;

    // Use anchor tag for external links (starting with http/https)
    const isExternal =
      href.startsWith('http://') || href.startsWith('https://');

    if (isExternal) {
      return (
        <a
          href={href}
          className={cn(
            'font-medium text-primary underline-offset-4 hover:underline',
            className
          )}
          target={target}
          rel={safeRel}
          ref={ref}
          {...props}
        >
          {children}
        </a>
      );
    }

    return (
      <RouterLink
        to={href}
        className={cn(
          'font-medium text-primary underline-offset-4 hover:underline',
          className
        )}
        target={target}
        rel={safeRel}
        ref={ref}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }
);

Link.displayName = 'Link';

export default Link;
