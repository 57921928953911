import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Input
} from '../../atoms';

interface ChangeUserProfileProps {
  userName: string;
  email: string;
  handleSubmit: (name: string) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ChangeUserProfile = ({
  userName,
  handleSubmit,
  email,
  isOpen,
  setIsOpen
}: ChangeUserProfileProps) => {
  const [name, setName] = useState(userName);
  const onSubmit = () => {
    handleSubmit(name);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Edit Profile</DialogTitle>
        </DialogHeader>
        <div className='grid gap-4 py-4'>
          <div className='grid gap-2'>
            <Input value={email} disabled />
          </div>
          <div className='grid gap-2'>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder='Enter your name'
            />
          </div>
        </div>
        <div className='flex justify-end gap-4'>
          <Button variant='outline' onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Save changes</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeUserProfile;
