import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListItemButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

const ListItemButton = React.forwardRef<HTMLButtonElement, ListItemButtonProps>(
  ({ className, children, disabled, selected, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          'relative flex w-full cursor-default select-none items-center gap-2',
          'rounded-sm px-3 py-2 text-sm outline-none',
          'transition-colors hover:bg-accent hover:text-accent-foreground',
          'focus:bg-accent focus:text-accent-foreground focus-visible:outline-none',
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          selected && 'bg-accent text-accent-foreground',
          className
        )}
        data-disabled={disabled}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  }
);
ListItemButton.displayName = 'ListItemButton';

export { ListItemButton };
export default ListItemButton;
