import { useCallback, useEffect, useMemo, useReducer } from 'react';
import ReactGA from 'react-ga4';
// context
// hooks
import { useJune } from '@hooks/use-june';
// utils
import { enqueueSnackbar } from 'notistack';
import { AuthContext } from './auth-context';
// types
import { identityClient } from '@lib/identity';
import { paths } from '@routes/paths';
import { isTokenExpired } from '@utils/jwt';
import { RoleType } from '@utils/roles';
import {
  clearSession,
  getSessionRefreshToken,
  getSessionToken,
  setSession,
  setSessionObject,
  setSessionRefreshToken
} from '@utils/session';
import { ActionMapType, AuthStateType, AuthUserType } from './types';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  RESET_PASSWORD = 'RESET_PASSWORD'
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.RESET_PASSWORD]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const { authAPI, usersAPI } = identityClient;

const initialState: AuthStateType = {
  user: null,
  loading: true
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null
    };
  }
  if (action.type === Types.RESET_PASSWORD) {
    return {
      ...state,
      user: null
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const analytics: ReturnType<typeof useJune> = useJune('q3ClmW5XJpuu6QHF');

  const initialize = useCallback(async () => {
    const currentPath = window.location.pathname;
    const isAuthPage = currentPath.startsWith(paths.auth.jwt.root);

    try {
      const accessToken = getSessionToken();
      const refreshToken = getSessionRefreshToken();
      // Clear session and redirect if no token or expired token
      if (!accessToken) {
        clearSession();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null
          }
        });
        if (!isAuthPage) {
          window.location.href = paths.auth.jwt.login;
        }
        return;
      }
      if (isTokenExpired(accessToken)) {
        if (!refreshToken) {
          clearSession();
          dispatch({ type: Types.INITIAL, payload: { user: null } });
          if (!isAuthPage) {
            window.location.href = paths.auth.jwt.login;
          }
          return;
        }
      }

      try {
        // Verify token by getting user info
        const { data: user } = await usersAPI.usersControllerGetUserInfo({
          headers: {
            Authorization: `Bearer ${
              isTokenExpired(accessToken) ? refreshToken : accessToken
            }`
          }
        });
        if (user) {
          // Valid user, set session data
          setSessionObject({ key: 'user', value: user });
          setSession(accessToken as string);
          setSessionRefreshToken(refreshToken as string);
          dispatch({
            type: Types.INITIAL,
            payload: {
              user
            }
          });
        } else {
          // No user data returned, clear session and redirect
          clearSession();
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null
            }
          });
          if (!isAuthPage) {
            window.location.href = paths.auth.jwt.login;
          }
        }
      } catch (error) {
        // API call failed, clear session and redirect
        clearSession();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null
          }
        });
        if (!isAuthPage) {
          window.location.href = paths.auth.jwt.login;
        }
        enqueueSnackbar('Session expired or invalid. Please log in again.', {
          variant: 'error'
        });
      }
    } catch (error) {
      // Unexpected error, clear session and redirect
      clearSession();
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null
        }
      });
      if (!isAuthPage) {
        window.location.href = paths.auth.jwt.login;
      }
      enqueueSnackbar('An unexpected error occurred. Please log in again.', {
        variant: 'error'
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (username: string, password: string) => {
      const data = {
        username,
        password
      };

      const loginResponse = await authAPI.authControllerSignIn(data);
      const accessToken = loginResponse.data.access_token;
      await setSession(accessToken as any);
      const { data: user } = await usersAPI.usersControllerGetUserInfo({
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setSessionObject({ key: 'user', value: user });

      const gaUserId = username;
      ReactGA.set({ userId: gaUserId });

      if (analytics) {
        analytics.identify(user.username.toLowerCase(), {
          email: user.username,
          name: user.name,
          country: user.country.name,
          client: !user?.roles?.includes(RoleType.MANAGER)
            ? user.client.name
            : '-',
          roles: user.roles
        });
        analytics.track(
          'Signed In',
          {
            browser: window.navigator.userAgent
          },
          {
            // Add the GROUP_ID here to track this event on behalf of a workspace
            context: {
              groupId: !user?.roles?.includes(RoleType.MANAGER)
                ? user.client.name
                : 'Customer Service'
            }
          }
        );
      }

      dispatch({
        type: Types.LOGIN,
        payload: {
          user
        }
      });
    },
    [analytics]
  );

  // RESET PASSWORD
  const resetPassword = useCallback(async (password: string, token: string) => {
    await authAPI.authControllerResetPassword(token, { password });

    dispatch({
      type: Types.RESET_PASSWORD
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    clearSession();
    dispatch({
      type: Types.LOGOUT
    });
  }, []);

  const token = getSessionToken();
  const authStatus =
    token && !isTokenExpired(token as string)
      ? 'authenticated'
      : 'unauthenticated';
  const status = state.loading ? 'loading' : authStatus;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      login,
      logout,
      resetPassword
    }),
    [login, logout, resetPassword, state.user, status]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
