import {
  closeSnackbar,
  SnackbarProvider as NotistackProvider
} from 'notistack';
import { useMemo, useRef } from 'react';

//
//
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
  Info,
  ListCollapse,
  X
} from 'lucide-react';
import { Button } from '../button';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  themeDirection: string;
};

const SnackbarProvider = ({ children, themeDirection }: Props) => {
  const isRTL = useMemo(() => themeDirection === 'rtl', [themeDirection]);
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      autoHideDuration={3000}
      TransitionComponent={isRTL ? () => <ListCollapse /> : undefined}
      variant='success' // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={{
        info: <Info size={24} className='text-blue-500 mx-0 mr-2' />,
        success: <CheckCircle2 size={24} className='text-green-500 mr-2' />,
        warning: <AlertTriangle size={24} className='text-yellow-500 mr-2' />,
        error: <AlertCircle width={20} className='text-red-500 mr-2' />
      }}
      style={{
        backgroundColor: 'white',
        accentColor: 'black',
        color: '#222'
      }}
      // with close as default
      action={snackbarId => (
        <Button
          variant='link'
          size='sm'
          onClick={() => closeSnackbar(snackbarId)}
          className='text-black'
        >
          <X size={16} />
        </Button>
      )}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
