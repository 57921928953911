import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from '@growdash/design-system/molecules';
import { DashboardLayout } from '../../layouts';
// hocs
import AuthGuard from '@hocs/auth-guard';
import { CheckPermission } from '@hocs/check-permission';
// auth
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';

// ----------------------------------------------------------------------

const GrowIntelPage = lazy(() => import('@pages/grow-intel/GrowIntel'));
const GrowIntelRestaurantPage = lazy(
  () => import('@pages/grow-intel/GrowIntelRestaurant')
);

// ----------------------------------------------------------------------

export const growIntelRoutes = [
  {
    path: paths?.growIntel?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths?.growIntel.root,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.competitors,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.item_search,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.market_changes,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.menu_suggestion,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.menu_analysis,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.popular_brands,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.popular_items,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel?.grow_intel_restaurant,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelRestaurantPage />
          </CheckPermission>
        )
      }
    ]
  }
];
