// routes
import Router from '@routes/index';
// Providers
import { AuthConsumer, AuthProvider } from '@context/auth';
import { SettingsProvider, useSettingsContext } from '@context/settings';
// additional service's configs
import useGoogleTranslate from '@integrations/google-translate';
import useLocalizeDocumentAttributes from '@integrations/i18n/useLocalizeDocumentAttributes';
import ProductFruitsOnboarding from '@integrations/products-fruits';
// components
import {
  LocalizationProvider,
  MotionLazy,
  SettingsDrawer,
  SnackbarProvider,
  TooltipProvider
} from '@growdash/design-system/atoms';
import { ThemeProvider } from './shared/providers/theme-provider';

import { useScrollToTop } from '@hooks/use-scroll-to-top';

export default function App() {
  useGoogleTranslate();
  useScrollToTop();
  useLocalizeDocumentAttributes();
  const settings = useSettingsContext();

  return (
    <ThemeProvider defaultTheme='system' storageKey='growdash-ui-theme'>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false
            }}
          >
            <MotionLazy>
              <TooltipProvider>
                <SnackbarProvider themeDirection={settings.themeDirection}>
                  <SettingsDrawer settings={settings} />
                  <AuthConsumer>
                    <ProductFruitsOnboarding />
                    <Router />
                  </AuthConsumer>
                </SnackbarProvider>
              </TooltipProvider>
            </MotionLazy>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
