import * as React from 'react';

export interface ListItemIconProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const ListItemIcon = React.forwardRef<HTMLDivElement, ListItemIconProps>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

ListItemIcon.displayName = 'ListItemIcon';

export default ListItemIcon;
