import { memo } from 'react';

// ----------------------------------------------------------------------

function CpcCalendarIllustration({ ...other }: any) {
  return (
    <svg
      className='h-full w-full'
      fill='none'
      viewBox='0 0 480 360'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <image
        href='/assets/illustrations/characters/character_1.png'
        height='800'
        x='150'
        y='30'
      />
    </svg>
  );
}

export default memo(CpcCalendarIllustration);
