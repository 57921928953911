import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListItemSecondaryActionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

const ListItemSecondaryAction = React.forwardRef<
  HTMLDivElement,
  ListItemSecondaryActionProps
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'ml-auto flex shrink-0 items-center justify-end gap-2',
        className
      )}
      {...props}
    />
  );
});

ListItemSecondaryAction.displayName = 'ListItemSecondaryAction';

export { ListItemSecondaryAction };
export default ListItemSecondaryAction;
