import { memo } from 'react';
import ApexChart from 'react-apexcharts';
// theme
import { cn } from '../../../../lib/utils'; // Utility for combining classes

// ----------------------------------------------------------------------

const Chart = ({ className, ...props }: any) => {
  return (
    <div className={cn('apexcharts-container', className)}>
      <ApexChart {...props} />
      <style>{`
        .apexcharts-tooltip {
          @apply backdrop-blur-md bg-gray-100 shadow-md rounded-md;
          background-color: #f3f4f6;
          color: #1f2937;
        }

        .apexcharts-xaxistooltip {
          @apply backdrop-blur-md bg-gray-100 shadow-md rounded-md;
          border-color: transparent;
          color: #1f2937;
        }

        .apexcharts-tooltip-title {
          @apply text-center font-bold;
          background-color: #e5e7eb;
          color: #1f2937;
        }

        .apexcharts-tooltip-marker {
        margin-right:15px !important;
        }

        .apexcharts-legend {
          padding: 0;
        }

        .apexcharts-legend-series {
          @apply inline-flex items-center  !important;
        }

        .apexcharts-legend-marker {
          margin-right: 0.5rem;
        }

        .apexcharts-legend-text {
          @apply capitalize;
          line-height: 1.125rem;
        }

        .apexcharts-xaxis-texts-g text {
          font-family: 'Public Sans';
          font-size: 0.75rem;
        }

        .apexcharts-xaxis-texts-g text tspan:first-of-type {

          fill: #1f2937;
          font-weight: 600;
        }

        .apexcharts-xaxis-texts-g text tspan:nth-of-type(2) {
          fill: #6b7280;
        }

        .apexcharts-datalabels text {
          font-family: 'Public Sans';
          font-size: 0.875rem;
          font-weight: 600;
          fill: #1f2937;
        }
      `}</style>
    </div>
  );
};

export default memo(Chart);
