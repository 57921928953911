import * as React from 'react';
import { cn } from '../../../lib/utils';

export interface ListItemTextProps
  extends React.HTMLAttributes<HTMLDivElement> {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
}

const ListItemText = React.forwardRef<HTMLDivElement, ListItemTextProps>(
  ({ className, primary, secondary, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex flex-col gap-0.5 min-w-0', className)}
        {...props}
      >
        {primary && (
          <span className='text-sm font-medium leading-none text-foreground truncate'>
            {primary}
          </span>
        )}
        {secondary && (
          <span className='text-md leading-relaxed text-muted-foreground truncate'>
            {secondary}
          </span>
        )}
      </div>
    );
  }
);
ListItemText.displayName = 'ListItemText';

export { ListItemText };
export default ListItemText;
