import { memo } from 'react';

//

// ----------------------------------------------------------------------

function FinanceIllustration({ ...other }: any) {
  return (
    <svg
      className='h-full w-full'
      fill='none'
      viewBox='0 0 480 360'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <image
        href='/assets/illustrations/illustration_dashboard.png'
        height='230'
        x='40'
        y='90'
      />
      <image
        href='/assets/illustrations/characters/character_3.png'
        height='300'
        x='322'
        y='30'
      />
    </svg>
  );
}

export default memo(FinanceIllustration);
