import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../button';

// ----------------------------------------------------------------------

type Props = {
  value: boolean;
  onChange: VoidFunction;
};

export default function StretchOptions({ value, onChange }: Props) {
  return (
    <Button
      onClick={onChange}
      variant='ghost'
      className={`
        w-full h-20 rounded-md transition-all duration-300
        border border-border/10
        ${
          value
            ? 'bg-background text-primary shadow-lg'
            : 'text-muted-foreground'
        }
      `}
    >
      <div
        className={`
          flex flex-row items-center justify-between
          transition-all duration-300
          ${value ? 'w-[50%]' : 'w-[24%]'}
        `}
      >
        {value ? <ChevronLeft /> : <ChevronRight />}
        <div className='flex-grow border-b border-dashed border-current' />
        {value ? <ChevronRight /> : <ChevronLeft />}
      </div>
    </Button>
  );
}
