// theme
// import { primaryPresets } from '@theme/options/presets';
import { cn } from '../../../../lib/utils';
import { primaryPresets } from '../../../theme/options/presets';
import { Button } from '../button';

// ----------------------------------------------------------------------

type PresetsOptionsProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export default function PresetsOptions({
  value,
  onChange
}: PresetsOptionsProps) {
  const options = primaryPresets.map(color => ({
    name: color.name,
    value: color.main
  }));

  // TODO: need to be check
  // columnGap={2}
  // rowGap={1.5}
  // display='grid'
  // gridTemplateColumns='repeat(3, 1fr)'

  ///

  //   width: 12,
  //   height: 12,
  //   borderRadius: '50%',
  //   bgcolor: option.value,
  //   transition: theme =>
  //     theme.transitions.create(['transform'], {
  //       duration: theme.transitions.duration.shorter
  //     }),
  //   ...(selected && {
  //     transform: 'scale(2)'
  //   })
  // }}
  return (
    <div className='grid grid-cols-3 gap-x-2 gap-y-1.5'>
      {options.map(option => {
        const selected = value === option.name;

        return (
          <Button
            key={option.name}
            onClick={() => onChange(option.name)}
            className={cn(
              'h-14 rounded border border-gray-500/[0.08]',
              'transition-all duration-200 hover:bg-accent/50',
              'focus-visible:outline-none focus-visible:ring-2',
              'focus-visible:ring-ring focus-visible:ring-offset-2',
              selected && ['border-transparent', 'bg-[var(--option-color)]']
            )}
            style={
              { '--option-color': `${option.value}0A` } as React.CSSProperties
            }
          >
            <div
              className={cn(
                'w-3 h-3 rounded-full transition-transform duration-200',
                selected && 'scale-[2]'
              )}
              style={{ backgroundColor: option.value }}
            />
          </Button>
        );
      })}
    </div>
  );
}
