import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
  PasswordInput
} from '../../atoms';

const ResetPasswordDialog = ({
  isOpen,
  setIsOpen,
  handleSubmit
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleSubmit: (newPassword: string, oldPassword: string) => void;
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const isPasswordsValid =
    newPassword?.length >= 8 && newPassword !== confirmPassword;

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      setError('New password cannot be the same as your old password.');
      return;
    }
    handleSubmit(newPassword, confirmPassword);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Reset Password</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Please enter your new password below.
        </DialogDescription>
        <Input
          onChange={e => setConfirmPassword(e.target.value)}
          type='password'
          placeholder='Confirm Old Password'
        />
        <PasswordInput
          password={newPassword}
          setPassword={setNewPassword}
          placeholder='New Password'
        />
        {error && <p className='text-red-500'>{error}</p>}
        <Button disabled={!isPasswordsValid} onClick={handleChangePassword}>
          Reset Password
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
